@import '@/app/styles/abstracts/shared-variables.scss';

.mainFooter {
  min-height: 232px;
  background: var(--footer-bg);

  @include media-breakpoint-down(lg) {
    min-height: 244px;
  }

  @layer bootstrap {
    :global(.link-primary) {
      color: var(--footer-link-color) !important;

      &:hover {
        color: var(--footer-link-hover-color) !important;
      }
    }

    small {
      display: block;
      color: var(--footer-dark-color);
    }
  }
}

.mainFooterDark {
  background: var(--footer-dark-bg);
}

.mainFooterRightColumn {
  text-align: right;
  @include media-breakpoint-down(lg) {
    text-align: left;
    margin-top: map-get($spacers, 3);
  }

  @layer bootstrap {
    :global(.link-primary) {
      color: var(--footer-dark-inline-link-color) !important;

      &:hover {
        color: var(--footer-link-hover-color) !important;
      }
    }

    small {
      display: block;
      color: var(--footer-dark-tertiary-color) !important;
    }
  }
}

.linksList {
  list-style: none;
  padding: 0;
  margin: 0;
}
